import { Checkbox, Col, Divider, Form, FormInstance, Input, Row } from 'antd';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { MasterDataResponse } from '../../models/responses/MasterDataResponse';
import { MasterDataService } from '../../services/masterDataService';
import { MasterDataType } from '../../constant/masterDataConstants';
import { HttpStatusCode } from 'axios';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Content } from 'antd/es/layout/layout';
import SelectProvince from '../../components/address/SelectProvince';
import SelectDistrict from '../../components/address/SelectDistrict';
import SelectSubDistrict from '../../components/address/SelectSubDistrict';
import TextArea from 'antd/es/input/TextArea';
import { IArtistResponse } from '../../models/responses/artistResponse';
import TranslationKey from 'src/i18n/translation';

interface IFormArtist {
  useFormArtist: FormInstance<any>;
  onSubmitArtist: (data: any) => void;
  artist: IArtistResponse | undefined;
}

const FormArtist = ({ useFormArtist, onSubmitArtist, artist }: IFormArtist) => {
  const [postcode, setPostCode] = useState('');
  const [districtId, setDistrictId] = useState(0);
  const [provinceId, setProvinceId] = useState(0);
  const [styleOfWork, setStyleOfWork] = useState<MasterDataResponse[]>([]);
  const [platForm, setPlatForm] = useState<MasterDataResponse[]>([]);
  const [isShowOtherStyleOfWork, setIsShowOtherStyleOfWork] = useState(false);
  const [isShowOtherPlatform, setIsShowOtherPlatform] = useState(false);
  const [isShowMySitePlatform, setIsShowMySitePlatform] = useState(false);
  const [subDistrictId, setSubDistrictId] = useState(0);

  const [form] = Form.useForm(useFormArtist);

  const getStyleOfWorkAsync = useCallback(async () => {
    const {
      data,
      status
    } = await new MasterDataService().getGeneticAsync<MasterDataResponse[]>(MasterDataType.STYLE_OF_WORK);

    if (status !== HttpStatusCode.Ok) {
      return;
    }

    setStyleOfWork(data);
  }, []);

  const getPlatformAsync = useCallback(async () => {
    const { data, status } = await new MasterDataService().getGeneticAsync<MasterDataResponse[]>(MasterDataType.PLATFORM);

    if (status !== HttpStatusCode.Ok) {
      return;
    }

    setPlatForm(data);
  }, []);

  useEffect(() => {
    if (artist) {
      setDistrictId(Number(artist.addressInfo.districtCode));
      setProvinceId(Number(artist.addressInfo.provinceCode));
      setSubDistrictId(Number(artist.addressInfo.subDistrictCode));
      setPostCode(artist.addressInfo.postcode.toString());

      const styleOfWork = JSON.parse(artist.styleOfWork) as string[];
      const platForm = JSON.parse(artist.platform) as string[];

      form.setFieldValue('styleOfWork', styleOfWork);
      form.setFieldValue('platForm', platForm);
    }
  }, [artist]);

  useLayoutEffect(() => {
    getStyleOfWorkAsync();
    getPlatformAsync();
  }, [getStyleOfWorkAsync, getPlatformAsync]);

  const validateCustomField = useCallback((isRequired: boolean, key: string) => {
    if (isRequired) {
      useFormArtist.setFields([
        {
          name: key,
          errors: [`${key} is required`],
        },
      ]);
    } else {
      useFormArtist.setFields([
        {
          name: key,
          errors: undefined,
        },
      ]);
    }
  }, [useFormArtist]);

  const onProvinceChange = (provinceId: number) => {
    setProvinceId(provinceId);
    useFormArtist.setFieldValue('ProvinceId', provinceId);
    validateCustomField(!provinceId, 'ProvinceId');
  };

  const onDistrictChange = (districtId: number) => {
    setDistrictId(districtId);
    useFormArtist.setFieldValue('DistrictId', districtId);
    validateCustomField(!districtId, 'DistrictId');
  };

  const onSubDistrictChange = (subDistrictId: number) => {
    setSubDistrictId(subDistrictId);
    useFormArtist.setFieldValue('SubDistriceId', subDistrictId);
    validateCustomField(!subDistrictId, 'SubDistriceId');
  };

  const onValidateSocial = () => {
    const facebook = useFormArtist.getFieldValue('facebook');
    const instagram = useFormArtist.getFieldValue('instagram');
    const tiktok = useFormArtist.getFieldValue('tiktok');
    const other = useFormArtist.getFieldValue('other');
    const validateSocial = facebook || instagram || tiktok || other;

    validateCustomField(!validateSocial, 'SocialGroup');
  };

  const onStyleOfWorkChange = useCallback((value: CheckboxChangeEvent) => {
    const targetId = value.target.value;
    const isChecked = value.target.checked;
    const object = styleOfWork.find(x => x.value === targetId);

    if (object && object.name === 'Other') {
      setIsShowOtherStyleOfWork(isChecked);
    }
  }, [styleOfWork]);

  const onPlatformChange = useCallback((value: CheckboxChangeEvent) => {
    const targetId = value.target.value;
    const isChecked = value.target.checked;
    const object = platForm.find(x => x.value === targetId);

    if (object && object.name === 'Other') {
      setIsShowOtherPlatform(isChecked);
    }

    if (object && object.name === 'My Site') {
      setIsShowMySitePlatform(isChecked);
    }
  }, [platForm]);

  const setStyleOfWorkCheckBox = (values: any) => {
    form.setFieldValue('styleOfWork', values);
  }

  return (
    <Content>
      <Form
        form={form}
        onFinish={onSubmitArtist}
        name="form_item_path"
        layout="vertical"
        className="form-input">
        <Row gutter={16}>
          <Col xs={24} lg={8}>
            <Form.Item
              name="email"
              className="mt-4"
              label={TranslationKey('artist.email')}
              rules={[{
                type: 'email',
                message: TranslationKey('artist.invalidEmailFormat')
              },
              {
                required: true,
                message: TranslationKey('artist.requireEmail')
              }]}>
              <Input
                className="w-100" placeholder="email" size="large" readOnly disabled />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="phone"
              className="mt-4"
              label={TranslationKey('artist.phoneNumber')}
              rules={[{
                required: true,
                message: TranslationKey('artist.requirePhoneNumber')
              }]}>
              <Input
                className="w-100"
                placeholder={TranslationKey('artist.phoneNumber')}
                size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} lg={8}>
            <Form.Item
              name="firstname"
              className="mt-4"
              label={TranslationKey('artist.firstNameEn')}
              rules={[{
                required: true,
                message: TranslationKey('artist.requireFirstNameEn')
              }]}>
              <Input
                className="w-100"
                placeholder={TranslationKey('artist.firstNameEn')}
                size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="lastname"
              className="mt-4"
              label={TranslationKey('artist.lastNameEn')}
              rules={[{
                required: true,
                message: TranslationKey('artist.requireLastNameEn')
              }]}>
              <Input
                className="w-100"
                placeholder={TranslationKey('artist.lastNameEn')}
                size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="nickname"
              className="mt-4"
              label={TranslationKey('artist.nickNameEn')}
              rules={[{
                required: true,
                message: TranslationKey('artist.requireNickNameEn')
              }]}>
              <Input
                className="w-100" placeholder="NicknameEn"
                size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="firstnameTh"
              className="mt-4"
              label={TranslationKey('artist.firstNameTh')}
              rules={[{
                required: true,
                message: TranslationKey('artist.requireFirstNameTh')
              }]}>
              <Input
                className="w-100"
                placeholder={TranslationKey('artist.firstNameTh')}
                size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="lastnameTh"
              className="mt-4"
              label={TranslationKey('artist.lastNameTh')}
              rules={[{
                required: true,
                message: TranslationKey('artist.requireLastNameTh')
              }]}>
              <Input
                className="w-100"
                placeholder={TranslationKey('artist.lastNameTh')}
                size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="nicknameTh"
              className="mt-4"
              label={TranslationKey('artist.nickNameTh')}
              rules={[{
                required: true,
                message: TranslationKey('artist.requireNickNameTh')
              }]}>
              <Input
                className="w-100"
                placeholder={TranslationKey('artist.nickNameTh')}
                size="large" />
            </Form.Item>
          </Col>
          <Divider className="text-primary">
            {TranslationKey('artist.artistAddress')}
          </Divider>
          <Col xs={24}>
            <Form.Item
              name="address"
              className="mt-4"
              label={TranslationKey('address.title')}
              rules={[{
                required: true,
                message: TranslationKey('address.requireAddress')
              }]}>
              <Input
                className="w-100"
                placeholder={TranslationKey('address')}
                size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item
              name="ProvinceId"
              label={TranslationKey('address.province')}
              rules={[{
                required: true,
                message: TranslationKey('address.requireProvince')
              }]}>
              <SelectProvince
                className="non-radius"
                provinceID={provinceId}
                sendProvinceID={onProvinceChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name="DistrictId"
              label={TranslationKey('address.district')}
              rules={[{
                required: true,
                message: TranslationKey('address.requireDistrict')
              }]}>
              <SelectDistrict
                className="non-radius"
                districtID={districtId}
                provinceID={provinceId}
                sendProvinceID={(id) => setProvinceId(id)}
                sendDistrictID={onDistrictChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name="SubDistriceId"
              label={TranslationKey('address.subDistrict')}
              rules={[{
                required: true,
                message: TranslationKey('address.requireSubDistrict')
              }]}>
              <SelectSubDistrict
                className="non-radius"
                postCode={postcode}
                districtID={districtId}
                provinceID={provinceId}
                subDistrictID={subDistrictId}
                sendPostCode={(value) => {
                  useFormArtist.setFieldValue('Postcode', value);
                }}
                sendSubDistrictID={onSubDistrictChange}
                sendDistrictID={(id, subId) => {
                  setDistrictId(id);
                  setSubDistrictId(subId);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name="Postcode" label="Postcode">
              <Input
                readOnly
                className="non-radius"
                size="large"
              />
            </Form.Item>
          </Col>
          <Divider className="text-primary">
            {TranslationKey('artist.artistDescription')}
          </Divider>
          <Col xs={24} lg={8}>
            <Form.Item
              name="portfolio"
              label={TranslationKey('artist.portfolio')}
              rules={[{
                required: true,
                message: TranslationKey('artist.requirePortfolio')
              },
              {
                type: 'url',
                message: TranslationKey('artist.invalidPortfolioFormat')
              }]}>
              <Input
                type='email'
                className="w-100"
                placeholder={TranslationKey('artist.portfolio')}
                size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={4}>
            <Form.Item
              name="facebook"
              label={TranslationKey('artist.facebook')}
            >
              <Input
                placeholder={TranslationKey('artist.facebook')}
                size="large"
                onChange={() => onValidateSocial()} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={4}>
            <Form.Item
              name="instagram"
              label={TranslationKey('artist.instagram')}
            >
              <Input
                placeholder={TranslationKey('artist.instagram')}
                size="large"
                onChange={() => onValidateSocial()} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={4}>
            <Form.Item
              name="tiktok"
              label={TranslationKey('artist.tiktok')}
            >
              <Input
                placeholder={TranslationKey('artist.tiktok')}
                size="large"
                onChange={() => onValidateSocial()} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={4}>
            <Form.Item
              name="other"
              label={TranslationKey('artist.other')}
            >
              <Input
                placeholder={TranslationKey('artist.other')}
                size="large"
                onChange={() => onValidateSocial()} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="aboutYourSelf"
              className="mt-4"
              label={TranslationKey('artist.aboutYourself')}
              rules={[{
                required: true,
                message: TranslationKey('artist.requireAnswerMessage')
              }]}>
              <TextArea className="w-100" rows={2}
                style={{ borderRadius: '4px' }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="selfPromotion"
              className="mt-4"
              label={TranslationKey('artist.definitionsOfPassion')}
              rules={[{
                required: true,
                message: TranslationKey('artist.requireAnswerMessage')
              }]}>
              <TextArea className="w-100" rows={2}
                style={{ borderRadius: '4px' }} />
            </Form.Item>
          </Col>
          <Divider className="text-primary">
            {TranslationKey('artist.styleOfWork')}
          </Divider>
          <Col span={12}>
            <Form.Item
              name="styleOfWork"
              className="mt-4"
              label={TranslationKey('artist.styleOfWork')}
            >
              <Checkbox.Group onChange={setStyleOfWorkCheckBox}>
                <Row>
                  {
                    styleOfWork
                      .sort((a, b) => a.sequence - b.sequence)
                      .map((item) =>
                        <Col key={item.sequence} span={24}>
                          <Checkbox onChange={onStyleOfWorkChange} value={item.value}>
                            {item.name}
                          </Checkbox>
                        </Col>)
                  }
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name="styleOfWorkOther"
              className={`small-input ${isShowOtherStyleOfWork ? '' : 'hide'}`}>
              <Input placeholder="Other" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="platForm"
              className="mt-4"
              label={TranslationKey('artist.doYouSellYourSomewhere')}
            >
              <Checkbox.Group>
                <Row>
                  {
                    platForm
                      .sort((a, b) => a.sequence - b.sequence)
                      .map((item) =>
                        <Col key={item.sequence} span={24}>
                          <Checkbox onChange={onPlatformChange}
                            value={item.value}>
                            {item.name}
                          </Checkbox>
                        </Col>)
                  }
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name="platFormMySite"
              className={`small-input ${isShowMySitePlatform ? '' : 'hide'}`}>
              <Input placeholder="MySite" size="large" />
            </Form.Item>
            <Form.Item
              name="platFormOther"
              className={`small-input ${isShowOtherPlatform ? '' : 'hide'}`}>
              <Input placeholder="Other" size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default FormArtist;