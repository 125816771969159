import { HttpStatus } from 'src/constant/responseStatus';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  message,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CollectionService } from 'src/services/collectionService';
import { RouteNames } from 'src/routes/routeName';
import GalleryDragItem from 'src/components/galleryItem/GalleryDragItem';
import update from 'immutability-helper';
import { CollectionAssetCreatingModel, CollectionAssetItemModel, CollectionAssetModel } from 'src/models/CollectionModel';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ExclamationCircleFilled } from '@ant-design/icons';
import TranslationKey from 'src/i18n/translation';

export default function MyCollectionDetail() {
  const [searchParams] = useSearchParams();
  const [collection, setCollection] = useState<CollectionAssetModel>({} as CollectionAssetModel);
  const [collectionItems, setCollectionItems] = useState<CollectionAssetItemModel[]>([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { confirm } = Modal;

  useEffect(() => {
    const collectionId = searchParams.get('id');

    if (collectionId) {
      getCollectionByIdAsync(collectionId);
      setIsEdit(true);
    }

  }, [searchParams]);

  const getCollectionByIdAsync = async (id: string) => {
    const { data, status } = await new CollectionService().GetCollectionById(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    form.setFieldValue('name', data.name);
    setCollection(data);
    setCollectionItems(data.collectionItems.sort((a, b) => a.coverSeq! - b.coverSeq!));
  };

  const onSaveCollection = async (values: any) => {
    let updItem: CollectionAssetItemModel[] = [];

    if (isEdit) {
      for (let index = 0; index < collectionItems.length; index++) {
        let newItem: CollectionAssetItemModel = {
          assetId: collectionItems[index].assetId,
          coverSeq: index + 1,
          id: collectionItems[index].id,
          asset: collectionItems[index].asset,
        };

        updItem.push(newItem);
      }

      const updateCollection: CollectionAssetModel = {
        id: collection.id,
        userId: collection.userId,
        name: values.name,
        collectionItems: updItem
      }

      await new CollectionService().UpdateCollection(updateCollection);

      await messageApi.open({
        type: 'success',
        content: TranslationKey('collectionDetail.saveCollectionSuccess'),
      });

      navigate(`${RouteNames.myCollections}`);

      return;
    }

    const newCollection: CollectionAssetCreatingModel = {
      name: values.name,
      collectionItems: updItem
    }

    const { status, data } = await new CollectionService().CreateCollection(newCollection);

    if (status !== HttpStatus.OK) {
      return;
    }

    await messageApi.open({
      type: 'success',
      content: TranslationKey('collectionDetail.saveCollectionSuccess'),
    });

    navigate(`${RouteNames.myCollections}`);
  };

  const onClickItem = (id: string) => {
    navigate(RouteNames.assetDetail.replace(':id', id));
  };

  const onDeleteCollection = async () => {
    confirm({
      title: TranslationKey('collectionDetail.doYouWantToDeleteThisCollection'),
      icon: <ExclamationCircleFilled className='txt-primary' />,
      okText: TranslationKey('button.confirm'),
      cancelText: TranslationKey('button.cancel'),
      okType: 'danger',
      async onOk() {
        const { status } = await new CollectionService().DeleteCollection(collection.id);

        if (status !== HttpStatus.OK) {
          message.error(TranslationKey('collectionDetail.deleteCollectionFail'));
          return;
        }

        message.success(TranslationKey('collectionDetail.deleteCollectionSuccess'));

        navigate(RouteNames.myCollections);
      },
      onCancel() { },

    });
  };

  const onDeleteCollectionItem = async (itemId: string) => {
    const { status } =
      await new CollectionService()
        .DeleteCollectionItem(
          collection.id,
          itemId);

    if (status !== HttpStatus.OK) {
      message.error(TranslationKey('collectionDetail.deleteCollectionItemFail'));
      return;
    }

    message.success(TranslationKey('collectionDetail.deleteCollectionItemSuccess'));
    getCollectionByIdAsync(collection.id);
  };

  const onConfirmDeleteCollectionItem = async (collectionItemId: string) => {
    confirm({
      title: TranslationKey('collectionDetail.doYouWantToDeleteThisCollectionItem'),
      icon: <ExclamationCircleFilled className='txt-primary' />,
      okText: TranslationKey('button.confirm'),
      cancelText: TranslationKey('button.cancel'),
      okType: 'danger',
      async onOk() {
        onDeleteCollectionItem(collectionItemId);
      },
      onCancel() { },
    });
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCollectionItems((prevCards: CollectionAssetItemModel[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as CollectionAssetItemModel],
        ],
      }),
    )
  }, [])

  return (
    <>
      {contextHolder}
      <Form form={form}
        layout='vertical'
        onFinish={onSaveCollection}
        initialValues={{
          type: 'model',
          gender: 0
        }}>
        <Card
          title={TranslationKey('collectionDetail.title')}
          bordered={false}
          extra={
            <div className='d-flex gap-2'>
              {
                collection && collection.id != null && collection.id != '' &&
                <Button type='primary'
                  size='large'
                  danger
                  disabled={collectionItems.length > 0}
                  onClick={onDeleteCollection}>
                  {TranslationKey('button.delete')}
                </Button>
              }

              <Button type='primary'
                htmlType='submit'
                size='large'>
                {TranslationKey('button.save')}
              </Button>
            </div>
          }
        >
          {
            collection && (
              <>
                <Row>
                  <Col span={6}>
                    <Form.Item
                      name='name'
                      label={TranslationKey('collectionDetail.collectionName')}
                      rules={[{ required: true, message: 'Please input your Collection Name!' }]}
                    >
                      <Input size='large' />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <DndProvider backend={HTML5Backend}>
                  <Row gutter={16}>
                    {
                      collectionItems && (
                        collectionItems
                          .map((d, index) =>
                            <Col key={index} xs={12} sm={6} md={6} lg={3} xl={3}>
                              <GalleryDragItem
                                key={index}
                                index={index}
                                asset={d.asset}
                                id={d.id}
                                width={'100%'}
                                height={'100%'}
                                onClickItem={onClickItem}
                                btnDelmarginTop={15}
                                btnDelmarginRight={23}
                                onDelete={onConfirmDeleteCollectionItem}
                                moveCard={moveCard}
                              />
                            </Col>
                          )
                      )
                    }
                  </Row>
                </DndProvider>
              </>
            )
          }
        </Card>
      </Form>
    </>
  );
}
